.content {
    padding: 32px;
}

.img-container {
    position: relative;
    display: inline-block; 
}

.example-img {
    cursor: pointer;
    width: 100%;
    height: 7.2rem;
}

.preview-img {
    width: 100%;
    max-width: 1333px;
    max-height: 1333px;
    border: 1px black solid;
}

.horizontal-gap {
    overflow: hidden;
    width: 8px;
}

.example-images {
    width: 50%;
}

.upload-form {
    width: 40%;
}

.output-form {
    width: 60%;
}

.form-range.form-control {
    border: none;
    padding-top: 19px;
    padding-bottom: 19px;
}

.form-range.form-control::-webkit-slider-thumb {
    background: black;
}

.form-range.form-control::-webkit-slider-thumb:hover {
    background: grey;
}

.page-not-found {
    text-align: center;
}

.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: grey;
    border-style: dashed;
    background-color: #e8e8e8;
    outline: none;
    transition: border .24s ease-in-out;
    overflow: hidden;
}

.dropzone p {
    text-align: center;
    color: grey;
}

.bpmn-container {
    padding: 32px;
}

.bpmn-overlay {
    position: relative;
    display: inline-block; 
    border: 1px black solid;
}

.bpmn-modeler {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
}

.background-img {
    filter: brightness(120%);
}