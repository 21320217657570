/* place palette to the right of to modeler */
.djs-palette {
    position: absolute;
    left: 100%;
    top: 0%;
    border: 1px black solid;
    border-left: none;
}
  
.djs-container.djs-palette-shown.djs-palette-two-column.djs-palette-open {
    overflow: visible !important;
}

/* disables process id being shown at the bottom when XML does not start with collaboration tag */
.bjs-breadcrumbs {
    visibility: hidden;
}